*{margin: auto; padding: 0}

.Homepage {
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  background-color: rgb(35,31,32);
  color: black;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
}

