
.Links{
margin: auto;
display: flex;
position: relative;
align-items: center;
align-content: center;
max-width: 100%;
min-width: 100%;
padding-top: 1%;
padding-bottom: 1%;
height: auto;
background-color: rgb(30,26,27);;
}


.LinkImage{
margin: auto;
background-color: transparent;
border-radius: 5px;
width: 7vmin;
height: 7vmin;
margin-left: 0.5vw;
margin-right: 0.5vw;
transition-property: width, height;
transition-duration: 0.5s;
transition-timing-function: ease-in-out;
}

.LinkImage:hover{
width: 8vmin;
height: 8vmin;
}


.fadeIn{
    animation: fadein;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    opacity: 1;
    transform: translate(0,0);
  }
  
  @keyframes fadein {
    from{
      opacity: 0;
      transform: translate(-10vw, 0);
    }
    to{
      opacity: 1;
      transform: translate(0,0);
    }
  }
  
  .fadeOut{
    animation: fadeout;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    opacity: 0;
    transform: translate(10vw, 0);
  }
  
  @keyframes fadeout {
    from{
      opacity: 1;
      transform: translate(0, 0);
    }
    to{
      opacity: 0;
      transform: translate(10vw, 0);
    }
  }
  