*{margin: auto; padding: 0}
.Banner{
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  font-family: din-condensed, sans-serif;
}


.projectHeader{
  color: white;
  font-size: 9vw;
  margin-top: 1vh;
  margin-bottom: 2.5rem;
  line-height: 90%;
  margin-right: 1rem;
  margin-left: 1rem;
  text-shadow: black 0.5vmin 1vmin;
}
.projButton{
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  color: white;
  padding: 2%;
  border: rgb(255, 255, 255) solid 0.1px;
  font-size: 2vmin;
  text-decoration: none;
}

.projButton:hover{
  padding: 2.5%;
  border: rgb(255, 255, 255) solid 1.5px;
}


.image{
  height: 45vw;
  width: 45vw;
  margin: 2.5rem 2.5rem 2.5rem 2.5rem;
  box-shadow: black 0.75vmin 0.75vmin;
}

.descriptionContainer{
  position: relative;
  width: 50vw;
}


.imageIn{
  animation: iIn;
  animation-duration: 0.75s;
  animation-timing-function: ease-in-out;
  transform: translate(0,0);
}


@keyframes iIn {
  from{
    transform: translate(-50vw, 0);
  }
  to{
    transform: translate(0, 0);
  }
}

.imageOut{
  animation: iOut;
  animation-duration: 0.75s;
  animation-timing-function: ease-in-out;
  transform: translate(-50vw, 0);
}

@keyframes iOut {
  from{
    transform: translate(0, 0);
  }
  to{
    transform: translate(-50vw, 0);
  }
}

.descIn{
  animation: dIn;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  opacity: 1;
}

@keyframes dIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

.descOut{
  animation: dOut;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  opacity: 0;
}

@keyframes dOut {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}

@media (max-aspect-ratio: 1/1) {
  .Banner{
    width: 100%;
    height: 100%;
  }
  .image{
    margin: auto;
    position: relative;
    width: 100vw;
    height: 100vw;
    z-index: 1;
  }

  .descriptionContainer{
    position: absolute;
    min-width: 75%;
    max-width: 75%;
    left: 12.5%;
    top: 35%;
    height: fit-content;
    z-index: 4;
  }

  .projectHeader{
    font-size: 12vw;
  }

  .projButton{
    backdrop-filter: blur(5px);
  }

  .imageIn{
    animation: none;
    transform: translate(0,0);
  }
  
  .imageOut{
    animation: none;
    transform: translate(0,0);
  }
  
  .descIn{
    animation: none;
    transform: translate(0,0);
    opacity: 1;
  }
  
  .descOut{
    animation: none;
    transform: translate(0,0);
    opacity: 1;
  }
}


  