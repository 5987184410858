*{margin: auto; padding: 0}

.inView{
  animation: in;
  animation-duration: 0.75s;
  animation-timing-function: ease-in-out;
  opacity: 1;
}

@keyframes in {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

.outView{
  animation: out;
  animation-duration: 0.75s;
  animation-timing-function: ease-in-out;
  opacity: 0;
}

@keyframes out {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}
.BriefAboutMe {
  position: relative;
  display: flex;
  margin: auto;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: din-condensed, sans-serif;

}

.Photo{
  width: 20vw;
  height: auto;
  z-index: 1;
}

.Icon{
  background-color: transparent;
  width: 65%;
  height: auto;
  transition-property: width, height;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}


.Name{
  display: inline-flex;
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  bottom: 30%;
  width: 70%;
  left: 15%;
  justify-self: center;
  align-self: center;
  z-index: 1;
  color:white;
  font-size: 2vmax;
  text-shadow: black 0.2vmin 0.4vmin;
}

.LogoContainer{
  display: grid;
  justify-items: center;
  position: relative;
  grid-auto-rows: repeat(5, 20%);
  width: 100%;
  height: 100%;
  overflow: hidden;
}


@media (max-aspect-ratio: 1/1) {
  .BriefAboutMe{
    width: 100%;
    overflow: hidden;
    object-fit: cover;
  }

  .Photo{
    width: 60%;
    height: auto;
  }

  .Name{
    width: 90%;
    left: 5%
  }
}